import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import * as React from "react";
import accountImage1 from "../../../images/account1.jpeg";
import accStore from "../../../stores/AccStore";
import { randomKey } from "../../Commons/functions";
import {
  AccountImage,
  AccountListContainer,
  AccountsListTitle,
  AccountsMenuList,
} from "./style";

interface AccountsListProps {
  visible: boolean;
}

export const AccountsList = ({ visible }: AccountsListProps): JSX.Element => {
  const handleAccountItemClick = (i: number) => {
    accStore.accountId = i.toString();
  };

  return (
    <AccountListContainer
      sx={{
        maxWidth: visible ? "400px" : "0px",
        maxHeight: !visible ? "0px" : "60vh",
      }}
    >
      <AccountsListTitle>
        <Typography variant="h6">Accounts</Typography>
      </AccountsListTitle>
      <AccountsMenuList>
        {[...Array(3)].map((i, n) => (
          <MenuItem
            key={randomKey(i)}
            onClick={() => handleAccountItemClick(n)}
          >
            <ListItemAvatar>
              <AccountImage src={accountImage1} alt="" />
            </ListItemAvatar>
            <Typography variant="body2">
              ACME Construction Company {n + 1}
            </Typography>
          </MenuItem>
        ))}
      </AccountsMenuList>
    </AccountListContainer>
  );
};
