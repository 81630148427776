import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import * as React from "react";
import projectImage0 from "../../../images/project0.jpeg";
import projectImage1 from "../../../images/project1.jpeg";
import projectImage2 from "../../../images/project2.jpeg";
import projectImage3 from "../../../images/project3.jpeg";
import projectImage4 from "../../../images/project4.jpeg";
import { randomKey } from "../../Commons/functions";
import {
  ProjectImage,
  ProjectListContainer,
  ProjectsListTitle,
  ProjectsMenuList,
} from "./style";
import { SelectedAccountDetails } from "../SelectedAccountDetails/SelectedAccountDetails";
import Divider from "@mui/material/Divider";

const projectImages = [
  projectImage0,
  projectImage1,
  projectImage2,
  projectImage3,
  projectImage4,
];

interface ProjectsListProps {
  visible: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ProjectsList = ({
  visible,
  onClick,
}: ProjectsListProps): JSX.Element => (
  <ProjectListContainer
    sx={{
      maxWidth: visible ? "400px" : "0px",
      maxHeight: !visible ? "0px" : "60vh",
    }}
  >
    <SelectedAccountDetails />
    <Divider />
    <ProjectsListTitle>
      <Typography variant="h6">Projects</Typography>
    </ProjectsListTitle>
    <ProjectsMenuList>
      {[...Array(30)].map((i, n) => (
        <MenuItem key={randomKey(i)} onClick={onClick}>
          <ListItemAvatar>
            <ProjectImage src={projectImages[n % 5]} alt="" />
          </ListItemAvatar>
          <Typography variant="body2">
            ACME Building Project #{n + 1}
          </Typography>
        </MenuItem>
      ))}
    </ProjectsMenuList>
  </ProjectListContainer>
);
