import { useObserver } from "mobx-react";
import { ProductSelectDropDownContainer } from "./style";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { products } from "../ProductsInstancesDataGrid/mocks";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Instance } from "../ProductsInstancesDataGrid/types";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

interface ProductSelectDropDownProps {
  value: string | undefined;
  onChange: (event: SelectChangeEvent) => void;
}

export const ProductSelectDropDown = ({
  value,
  onChange,
}: ProductSelectDropDownProps): JSX.Element =>
  useObserver(() => (
    <ProductSelectDropDownContainer>
      <Typography variant="body2">Product</Typography>
      <Select
        size="small"
        id="select-product-id"
        labelId="select-product-id-label"
        value={value}
        onChange={onChange}
        sx={{ minWidth: "200px" }}
      >
        {products.map((product) => (
          <MenuItem key={`product-${product.id}`} value={product.id}>
            <Typography variant="body2">{product.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </ProductSelectDropDownContainer>
  ));

interface ExpandToggleButtonProps {
  expanded: boolean;
  onToggle: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ExpandToggleButton = ({
  expanded,
  onToggle,
}: ExpandToggleButtonProps): JSX.Element => (
  <IconButton onClick={onToggle}>
    {expanded && <KeyboardDoubleArrowLeft />}
    {!expanded && <KeyboardDoubleArrowRight />}
  </IconButton>
);

export const GenerateOutputsButton = (): JSX.Element => (
  <Button variant="outlined">Generate Outputs</Button>
);

interface AssignButtonsProps {
  selectedInstances: Instance[];
  onAssign: (event: React.MouseEvent<HTMLElement>) => void;
  onRelease: (event: React.MouseEvent<HTMLElement>) => void;
}

export const AssignButtons = ({
  selectedInstances,
  onAssign,
  onRelease,
}: AssignButtonsProps): JSX.Element => {
  const assignDisabled = !selectedInstances.find(
    (instance) => !instance.assigned
  );

  const releaseDisabled = !selectedInstances.find(
    (instance) => instance.assigned
  );

  return (
    <ButtonGroup size="small">
      <Button
        onClick={onAssign}
        disabled={assignDisabled}
        variant={!assignDisabled ? "contained" : undefined}
      >
        Assign
      </Button>
      <Button onClick={onRelease} disabled={releaseDisabled}>
        Release
      </Button>
    </ButtonGroup>
  );
};

interface AssignmentFilterToggleProps {
  value: string;
  onToggle: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  instances?: Instance[];
}

export const AssignmentFilterToggle = ({
  value,
  onToggle,
  instances,
}: AssignmentFilterToggleProps): JSX.Element => {
  const countAssigned = instances
    ? instances.filter((instance) => instance.assigned).length
    : 0;
  const countUnassigned = instances
    ? instances.filter((instance) => !instance.assigned).length
    : 0;
  const countAll = instances ? instances.length : 0;

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={onToggle}
      size="small"
    >
      <ToggleButton value="assigned">
        <Typography variant="body2" noWrap>
          Assigned ({countAssigned})
        </Typography>
      </ToggleButton>
      <ToggleButton value="unassigned">
        <Typography variant="body2" noWrap>
          Unssigned ({countUnassigned})
        </Typography>
      </ToggleButton>
      <ToggleButton value="all">
        <Typography variant="body2" noWrap>
          All ({countAll})
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
