import * as React from "react";
import lmvImage from "../../../images/lmv.png";

export const ModelViewer = (): JSX.Element => (
  <img
    src={lmvImage}
    alt=""
    style={{ display: "block", width: "100%", height: "100%" }}
  />
);
