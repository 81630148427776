import * as React from "react";

import Avatar from "@mui/material/Avatar";
import userImage1 from "../../../images/treau.jpeg";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import {
  UserSettingsAvatar,
  UserSettingsContents,
  UserSettingsHeaderContainer,
  UserSettingsHeaderUsername,
  UserSettingsPopoverContainer,
} from "./style";

export function UserSettingsPanel(): JSX.Element {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <UserSettingsHeaderContainer onClick={handleOpenUserMenu}>
        <Avatar
          sx={{ margin: "10px", width: "32px", height: "32px" }}
          alt="John Doe"
          src={userImage1}
        />
        <UserSettingsHeaderUsername>
          <Typography variant="body2" noWrap component="div">
            Anh Gentlesmile
          </Typography>
        </UserSettingsHeaderUsername>
      </UserSettingsHeaderContainer>
      <Popover
        id="userPopover"
        open={Boolean(anchorElUser)}
        anchorEl={anchorElUser}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <UserSettingsPopoverContainer>
          <UserSettingsAvatar alt="John Doe" src={userImage1} />
          <UserSettingsContents>
            <Typography variant="h6">Anh Gentlesmile</Typography>
            <Typography variant="body2">
              anh.gentlesmile@autodesk.com
            </Typography>
          </UserSettingsContents>
        </UserSettingsPopoverContainer>
      </Popover>
    </>
  );
}
