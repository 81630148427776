import { FolderTreeItemProps } from "./FolderItem";

export const mockFolderTree: FolderTreeItemProps[] = [
  {
    id: "1",
    label: "Project Files",
    subFolders: [
      { id: "1.1", label: "Architectural" },
      {
        id: "1.2",
        label: "Design Team",
        subFolders: [
          { id: "1.2.1", label: "Shared" },
          {
            id: "1.2.2",
            label: "WIP",
            subFolders: [...Array(40)].map((i, n) => ({
              id: `1.2.2.${n + 1}`,
              label: `Project ${n + 1}`,
            })),
          },
        ],
      },
      { id: "1.3", label: "Electrical" },
      { id: "1.4", label: "Mechanical" },
      {
        id: "1.5",
        label: "Preconstruction",
        subFolders: [{ id: "1.5.1", label: "Coordination" }],
      },
    ],
  },
];
