import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import { MouseEventHandler } from "react";

export interface ConfirmationDialogProps {
  open: boolean;
  message: string;
  title?: string;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
}

export const ConfirmationDialog = ({
  open,
  message,
  title,
  onCancel,
  onConfirm,
}: ConfirmationDialogProps): JSX.Element => (
  <Modal
    keepMounted
    open={open}
    onClose={onCancel}
    aria-labelledby="keep-mounted-modal-title"
    aria-describedby="keep-mounted-modal-description"
  >
    <Box
      sx={{
        margin: "auto",
        marginTop: "30vh",
        maxWidth: "600px",
        backgroundColor: "white",
        borderRadius: "2px",
        boxShadow: "0px 0px 10px 0px #444",
      }}
    >
      <Box sx={{ padding: "24px" }}>
        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
          {title || "Confirmation"}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: "24px" }}>
        <Typography id="keep-mounted-modal-title" variant="body1" component="p">
          {message}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "24px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: "8px" }}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  </Modal>
);
