import { makeAutoObservable } from "mobx";
import { Instance } from "../components/ProductsPage/ProductsInstancesDataGrid/types";

class SettingsStore {
  showInstanceDetailsPanel = false;
  selectedInstances: Instance[] = [];

  productsSelectedTab = "models";

  assignFilter = "all";

  portalPassword?: string | null;

  instanceFocusId?: string | null;

  constructor() {
    makeAutoObservable(this);
  }
}

const settingsStore = new SettingsStore();

export default settingsStore;
