import * as React from "react";
import AppBar from "@mui/material/AppBar";
import UserSettingsPanel from "../UserSettingsPanel";
import { ThemeProvider } from "@mui/material/";
import logoMid from "../../../images/logo.png";
import ProjectSelectorPanel from "../ProjectSelectorPanel";
import {
  AppLogoContainer,
  AppLogoImage,
  HeaderAppBarContainer,
  HeaderDarkTheme,
  HeaderToolbarContainer,
} from "./style";

export function HeaderAppBar(): JSX.Element {
  return (
    <HeaderAppBarContainer>
      {/*<Box sx={{ backgroundColor: "black", padding: "3px 0px 0px 10px" }}>*/}
      {/*  <img src={logoMid} alt="" style={{ height: "18px" }} />*/}
      {/*</Box>*/}
      <ThemeProvider theme={HeaderDarkTheme}>
        <AppBar position="static" elevation={0}>
          <HeaderToolbarContainer variant="dense">
            <AppLogoContainer>
              <AppLogoImage src={logoMid} alt="" />
            </AppLogoContainer>
            <ProjectSelectorPanel />
            <UserSettingsPanel />
          </HeaderToolbarContainer>
        </AppBar>
      </ThemeProvider>
    </HeaderAppBarContainer>
  );
}
