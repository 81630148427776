import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { LabelValueTableProps } from "./types";
import { randomKey } from "../functions";

export const LabelValueTable = ({
  data,
}: LabelValueTableProps): JSX.Element => (
  <TableContainer sx={{ margin: "0px 12px" }}>
    <Table>
      {data.map((row) => (
        <TableRow key={randomKey(row.value)}>
          <TableCell
            sx={{ padding: "5px 6px", backgroundColor: "#eee", width: "50%" }}
          >
            <Typography noWrap variant="body2">
              {row.label}
            </Typography>
          </TableCell>
          <TableCell
            sx={{ padding: "5px 6px", backgroundColor: "#fff", width: "50%" }}
          >
            <Typography noWrap variant="body2">
              {row.value}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </TableContainer>
);
