import "./App.css";
import Box from "@mui/material/Box";
import HeaderAppBar from "./components/Header/";
import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import { createTheme, ThemeProvider } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProductsPage from "./pages/ProductsPage";
import Typography from "@mui/material/Typography";
import settingsStore from "./stores/SettingsStore";
import { PortalPassword } from "./components/PortalPassword/PortalPassword";

export const App = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState("products");

  useEffect(() => {
    settingsStore.portalPassword =
      window.localStorage.getItem("portalPassword");
  }, []);

  const theme = createTheme();
  theme.components = {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
  };

  theme.typography.button.textTransform = "none";

  const handleChangeTab = (_: any, value: string) => {
    setSelectedTab(value);
  };

  return useObserver(() => (
    <ThemeProvider theme={theme}>
      {settingsStore.portalPassword ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <HeaderAppBar />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab
                value="products"
                label={<Typography variant="body1">Products</Typography>}
              />
              <Tab
                value="outputs"
                label={<Typography variant="body1">Outputs</Typography>}
              />
              <Tab
                value="insights"
                label={<Typography variant="body1">Insights</Typography>}
              />
            </Tabs>
          </Box>
          {selectedTab === "products" && <ProductsPage />}
        </Box>
      ) : (
        <PortalPassword />
      )}
    </ThemeProvider>
  ));
};

export default App;
