import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TreeView } from "@mui/lab";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { FolderItem } from "./FolderItem";
import Divider from "@mui/material/Divider";
import { mockFolderTree } from "./mock";
import { useState } from "react";
import { randomKey } from "../../Commons/functions";

export const ModelsFolderBrowser = (): JSX.Element => {
  const defaultExpanded = ["1", "1.2", "1.5"];
  const [expandedNodes, setExpandedNodes] = useState(defaultExpanded);

  const handleNodeToggle = (event: React.SyntheticEvent, nodes: string[]) => {
    setExpandedNodes(nodes);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: "2",
        minWidth: "200px",
        maxWidth: "250px",
        alignItems: "stretch",
        paddingLeft: "4px",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "60px",
          maxHeight: "60px",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "8px",
        }}
      >
        <Typography variant="body1" noWrap>
          Select a model to assign instances
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: "10",
          overflow: "scroll",
          marginTop: "8px",
          padding: "8px",
        }}
      >
        <TreeView
          aria-label="folders"
          defaultExpanded={defaultExpanded}
          defaultCollapseIcon={<ArrowDropDown />}
          defaultExpandIcon={<ArrowRight />}
          onNodeToggle={handleNodeToggle}
        >
          {mockFolderTree
            .map((item) => ({ expanded: expandedNodes, ...item }))
            .map((item) => (
              <FolderItem key={randomKey(item.id)} {...item} />
            ))}
        </TreeView>
      </Box>
    </Box>
  );
};
