import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridState,
} from "@mui/x-data-grid";
import settingsStore from "../../../stores/SettingsStore";
import { useObserver } from "mobx-react";
import midStore from "../../../stores/MidStore";
import { InputType } from "./types";
import { HigEditProfile } from "../../Commons/CustomIcon/HigIcons";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProductsInstancesDataGrid = (): JSX.Element => {
  const handleStateChange = (state: GridState) => {
    if (state.selection.length === 0) {
      settingsStore.showInstanceDetailsPanel = false;
    } else if (
      state.selection.length > settingsStore.selectedInstances.length &&
      JSON.stringify(settingsStore.selectedInstances) !==
        JSON.stringify(state.selection)
    ) {
      settingsStore.showInstanceDetailsPanel = true;
    }

    settingsStore.selectedInstances = midStore.instances
      ? midStore.instances.filter((instance) =>
          state.selection.includes(instance.id)
        )
      : [];

    settingsStore.instanceFocusId = state.focus.cell?.id.toString();
  };

  const filteredInstances = () =>
    midStore.instances
      ? midStore.instances
          .filter(
            (instance) => instance.productId === midStore.selectedProduct?.id
          )
          .filter((instance) => {
            switch (settingsStore.assignFilter) {
              case "assigned":
                return instance.assigned;
              case "unassigned":
                return !instance.assigned;
              default:
                return true;
            }
          })
          .map((instance: any) => {
            const newInstance = instance.inputs.reduce(
              (newInstance: any, input: any) => {
                newInstance[input.name] = input.value;
                return newInstance;
              },
              {}
            );

            return {
              id: instance.id,
              elementId: instance.elementId,
              variantName: instance.variantName,
              assigned: instance.assigned,
              ...newInstance,
            };
          })
      : [];

  const renderAssignedColumn = (
    params: GridRenderCellParams
  ): React.ReactNode => <>{params.value && <HigEditProfile />}</>;

  const columns: GridColDef[] = [
    {
      field: "assigned",
      headerName: "Assigned",
      width: 10,
      renderCell: renderAssignedColumn,
    },
    { field: "elementId", headerName: "ID", width: 90 },
    {
      field: "variantName",
      headerName: "Variant",
      width: 200,
    },
  ];

  midStore.selectedProduct?.inputs.forEach((input) => {
    columns.push({
      field: input.name,
      headerName: input.unit ? `${input.name} (${input.unit})` : input.name,
      width: 60 + Math.trunc(input.name.length * 10),
      align: input.type === InputType.NumberType ? "right" : "left",
    });
  });

  return useObserver(() => (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#eee",
        },
        fontSize: "14px",
      }}
      rows={filteredInstances()}
      columns={columns}
      rowsPerPageOptions={[5]}
      checkboxSelection
      disableSelectionOnClick
      rowHeight={34}
      headerHeight={34}
      onStateChange={handleStateChange}
    />
  ));
};

export default ProductsInstancesDataGrid;
