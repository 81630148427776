import { makeAutoObservable } from "mobx";
import {
  Instance,
  Product,
} from "../components/ProductsPage/ProductsInstancesDataGrid/types";

class MidStore {
  selectedProduct?: Product;
  instances?: Instance[];

  constructor() {
    makeAutoObservable(this);
  }
}
const midStore = new MidStore();

export default midStore;
