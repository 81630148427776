import { styled } from "@mui/material/styles";
import MenuList from "@mui/material/MenuList";
import Box from "@mui/material/Box";

export const ProjectListContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  transition: "all .3s ease",
  overflow: "hidden",
}));

export const ProjectsListTitle = styled(Box)(() => ({
  margin: "8px 12px 0px 12px",
}));

export const ProjectsMenuList = styled(MenuList)(() => ({
  maxHeight: "600px",
  flexGrow: 10,
  overflow: "scroll",
}));

export const ProjectImage = styled("img")(() => ({
  width: "38px",
  height: "30px",
}));
