import React from "react";
import accountImage1 from "../../../images/account1.jpeg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import accStore from "../../../stores/AccStore";
import {
  AccountDetails,
  AccountImage,
  AccountTitleContainer,
  SelectedAccountDetailsContainer,
} from "./style";

export const SelectedAccountDetails = (): JSX.Element => {
  const handleChangeAccount = () => {
    accStore.accountId = null;
  };

  return (
    <SelectedAccountDetailsContainer>
      <AccountImage src={accountImage1} alt="" />
      <AccountTitleContainer>
        <Typography variant="body2" noWrap component="div">
          ACME Construction Company
        </Typography>
        <AccountDetails>
          <Typography variant="body2" noWrap component="div">
            10 Projects --
          </Typography>
          <Button onClick={handleChangeAccount}>Change Account</Button>
        </AccountDetails>
      </AccountTitleContainer>
    </SelectedAccountDetailsContainer>
  );
};
