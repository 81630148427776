import React from "react";

export interface CustomIconProps {
  icon: string;
  width: string;
  height?: string;
  opacity?: number;
}

export const CustomIcon: React.FC<CustomIconProps> = ({
  icon,
  width,
  height,
  opacity,
}: CustomIconProps) => (
  <img
    src={icon}
    alt=""
    style={{
      width,
      height: height || width,
      opacity: opacity || undefined,
    }}
  />
);
