import * as React from "react";

import { CustomPaginationContainer } from "./style";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

export interface CustomPaginationProps {
  count: number;
  currentIndex: number;
  onChange: (
    index: number,
    previousIndex?: number,
    event?: React.MouseEvent<HTMLElement>
  ) => void;
}
export const CustomPagination = ({
  count,
  currentIndex,
  onChange,
}: CustomPaginationProps): JSX.Element => {
  if (currentIndex < 0) {
    onChange(0, currentIndex);
  }

  if (currentIndex > count) {
    onChange(count - 1, currentIndex);
  }

  const handleFirst = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex > 0) {
      onChange(0, currentIndex, event);
    }
  };

  const handlePrevious = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex > 0) {
      onChange(currentIndex - 1, currentIndex, event);
    }
  };

  const handleNext = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex < count - 1) {
      onChange(currentIndex + 1, currentIndex, event);
    }
  };

  const handleLast = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex < count) {
      onChange(count - 1, currentIndex, event);
    }
  };

  return (
    <CustomPaginationContainer>
      <IconButton onClick={handleFirst} disabled={currentIndex === 0}>
        <FirstPage />
      </IconButton>
      <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
        <NavigateBefore />
      </IconButton>
      <Typography variant="body2">
        {currentIndex + 1} of {count}
      </Typography>
      <IconButton onClick={handleNext} disabled={currentIndex === count - 1}>
        <NavigateNext />
      </IconButton>
      <IconButton onClick={handleLast} disabled={currentIndex === count - 1}>
        <LastPage />
      </IconButton>
    </CustomPaginationContainer>
  );
};
