import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

export const UserSettingsHeaderContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  cursor: "pointer",
}));

export const UserSettingsHeaderUsername = styled(Box)(() => ({
  minWidth: "100px",
  maxWidth: "200px",
  overflow: "hidden",
}));

export const UserSettingsPopoverContainer = styled(Box)(() => ({
  display: "flex",
  padding: "20px 30px ",
}));

export const UserSettingsAvatar = styled(Avatar)(() => ({
  width: 56,
  height: 56,
}));

export const UserSettingsContents = styled(Box)(() => ({
  paddingLeft: "10px",
}));
