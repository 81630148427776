import * as React from "react";

import { SelectChangeEvent } from "@mui/material/Select";

import { useState } from "react";

import InstanceDetailsPanel from "../InstanceDetailsPanel";
import ProductsInstancesDataGrid from "../ProductsInstancesDataGrid";
import settingsStore from "../../../stores/SettingsStore";

import { ModelViewer } from "../ModelViewer/ModelViewer";
import midStore from "../../../stores/MidStore";
import { useObserver } from "mobx-react";
import { products } from "../ProductsInstancesDataGrid/mocks";

import {
  InstanceSidebarHeader,
  InstanceSidebarHeaderGroup,
  InstanceSidebarHeaderRow,
  InstancesSidebar,
  ModelViewContainer,
} from "./style";
import {
  AssignButtons,
  AssignmentFilterToggle,
  ExpandToggleButton,
  GenerateOutputsButton,
  ProductSelectDropDown,
} from "./InstancePanelComponents";

export const InstancesPanel = (): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  const [selectedProductId, setSelectedProductId] = useState(
    midStore.selectedProduct?.id || (products && products[0].id)
  );

  if (!midStore.selectedProduct) {
    midStore.selectedProduct = products[0];
  }

  const handleAssignFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    settingsStore.assignFilter = value;
  };

  const handleSelectedProductIdChange = (event: SelectChangeEvent) => {
    midStore.selectedProduct = products.find(
      (product) => product.id === event.target.value
    );
    setSelectedProductId(event.target.value);
  };

  const handleToggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleAssignInstances = () => {
    settingsStore.selectedInstances.forEach((selectedInstance) => {
      selectedInstance.assigned = true;
      midStore.instances?.forEach((instance) => {
        if (instance.id === selectedInstance.id) {
          instance.assigned = true;
        }
      });
    });
  };

  const handleReleaseInstances = () => {
    settingsStore.selectedInstances.forEach((selectedInstance) => {
      selectedInstance.assigned = false;
      midStore.instances?.forEach((instance) => {
        if (instance.id === selectedInstance.id) {
          instance.assigned = false;
        }
      });
    });
  };

  return useObserver(() => (
    <>
      <InstancesSidebar sx={{ maxWidth: expanded ? "100%" : "500px" }}>
        <InstanceSidebarHeader>
          <InstanceSidebarHeaderRow>
            <InstanceSidebarHeaderGroup>
              {expanded && (
                <AssignButtons
                  selectedInstances={settingsStore.selectedInstances}
                  onAssign={handleAssignInstances}
                  onRelease={handleReleaseInstances}
                />
              )}
              <AssignmentFilterToggle
                value={settingsStore.assignFilter}
                onToggle={handleAssignFilterChange}
                instances={midStore.instances}
              />
              {expanded && (
                <ProductSelectDropDown
                  value={selectedProductId}
                  onChange={handleSelectedProductIdChange}
                />
              )}
              <GenerateOutputsButton />
            </InstanceSidebarHeaderGroup>
            <ExpandToggleButton
              expanded={expanded}
              onToggle={handleToggleExpanded}
            />
          </InstanceSidebarHeaderRow>
          {!expanded && (
            <InstanceSidebarHeaderRow>
              <ProductSelectDropDown
                value={selectedProductId}
                onChange={handleSelectedProductIdChange}
              />
              <AssignButtons
                selectedInstances={settingsStore.selectedInstances}
                onAssign={handleAssignInstances}
                onRelease={handleReleaseInstances}
              />
            </InstanceSidebarHeaderRow>
          )}
        </InstanceSidebarHeader>
        <ProductsInstancesDataGrid />
      </InstancesSidebar>

      {settingsStore.showInstanceDetailsPanel && <InstanceDetailsPanel />}
      {!expanded && (
        <ModelViewContainer>
          <ModelViewer />
        </ModelViewContainer>
      )}
    </>
  ));
};
