const mockCategories = ["House", "Condo", "School", "Building", "Townhouse"];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export interface MockFolderContentRow {
  fileName: string;
  modifiedOn: string;
  instancesCount: number;
  id: number;
  productsCount: number;
  version: string;
  variantsCount: number;
  assignedCount: number;
}

export const mockFolderContent: MockFolderContentRow[] = [...Array(100)].map(
  () => {
    const productsCount = 1 + Math.trunc(Math.random() * 10);
    const variantsCount =
      productsCount * Math.trunc(Math.random() * 10) +
      Math.trunc(Math.random() * 10);
    const instancesCount =
      variantsCount * Math.trunc(Math.random() * 10) +
      Math.trunc(Math.random() * 10);
    const assignedCount = Math.trunc(Math.random() * instancesCount);

    const modifiedTime = new Date();
    modifiedTime.setTime(
      modifiedTime.getTime() - Math.trunc(Math.random() * 30000000000)
    );

    const modifiedOn =
      months[modifiedTime.getMonth()] +
      " " +
      modifiedTime.getDate() +
      ", " +
      modifiedTime.getFullYear() +
      " " +
      modifiedTime.getHours() +
      ":" +
      modifiedTime.getMinutes();

    const fileName =
      300000 +
      Math.trunc(Math.random() * 100000) +
      " - " +
      mockCategories[Math.trunc(Math.random() * mockCategories.length)] +
      " - " +
      (400 + Math.trunc(Math.random() * 10000)) +
      " sqft ";

    return {
      id: 300000 + Math.trunc(Math.random() * 100000),
      fileName,
      version: "V1",
      modifiedOn,
      productsCount,
      variantsCount,
      instancesCount,
      assignedCount,
    };
  }
);
