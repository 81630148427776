import { InputType, Product, Variant } from "./types";
import midStore from "../../../stores/MidStore";

const randomInt = (max: number, base = 0) =>
  base + Math.trunc(Math.random() * max);

const randomItem = (arr: any[]) => arr[randomInt(arr.length)];

export const products: Product[] = [
  {
    id: "0001",
    name: "Balcony",
    inputs: [
      { name: "Width", type: InputType.NumberType, unit: "ft" },
      { name: "Height", type: InputType.NumberType, unit: "ft" },
      { name: "Finish", type: InputType.StringType },
      { name: "Panes", type: InputType.StringType },
      { name: "Tint", type: InputType.StringType },
      { name: "Tint Color", type: InputType.StringType },
      { name: "Insect Screen", type: InputType.StringType },
      { name: "Locking Mechanism", type: InputType.StringType },
    ],
  },
  {
    id: "0002",
    name: "Wall",
    inputs: [
      { name: "Width", type: InputType.NumberType, unit: "ft" },
      { name: "Height", type: InputType.NumberType, unit: "ft" },
      { name: "Insect Screen", type: InputType.StringType },
      { name: "Locking Mechanism", type: InputType.StringType },
    ],
  },
  {
    id: "0003",
    name: "Wall with Door",
    inputs: [
      { name: "Width", type: InputType.NumberType, unit: "ft" },
      { name: "Height", type: InputType.NumberType, unit: "ft" },
      { name: "Opening", type: InputType.StringType },
      { name: "Tint", type: InputType.StringType },
      { name: "Tint Color", type: InputType.StringType },
      { name: "Insect Screen", type: InputType.StringType },
      { name: "Locking Mechanism", type: InputType.StringType },
    ],
  },
];

export const variants: Variant[] = [...Array(randomInt(40, 20))].map(() => {
  const product = products[randomInt(products.length)];

  const width = randomInt(20, 6);
  const height = randomInt(10, 6);
  const inputs = [
    { name: "Width", unit: "ft", value: width },
    { name: "Height", unit: "ft", value: height },
    { name: "Tint", unit: "", value: randomItem(["On", "Off"]) },
    {
      name: "Tint Color",
      unit: "",
      value: randomItem(["None", "Blue", "Gray"]),
    },
    { name: "Insect Screen", unit: "", value: randomItem(["Yes", "No"]) },
    {
      name: "Locking Mechanism",
      unit: "",
      value: randomItem(["Eco", "Standard"]),
    },
  ];

  if (product.id === "0001") {
    inputs.splice(2, 0, {
      name: "Finish",
      unit: "uL",
      value: randomItem([
        "Anodized Black",
        "Anodized White",
        "Anodized Gray",
        "Powder Coated Antharcite",
        "Anodized Clear",
      ]),
    });
    inputs.push({
      name: "Panes",
      unit: "uL",
      value: Math.ceil(width / 3),
    });
  }
  if (product.id === "0003") {
    inputs.splice(2, 0, {
      name: "Opening",
      unit: "uL",
      value: randomItem(["Center", "Left", "Right"]),
    });
  }

  return {
    id: ` ${product.id}-${randomInt(1000000, 100000)}`,
    name: `${product.name} ${width}ft x ${height}ft`,
    productId: product.id,
    productName: product.name,
    category: product.id === "0001" ? "Generic" : "Walls",
    inputs,
  };
});

midStore.instances = [...Array(randomInt(100, variants.length))].map(() => {
  const variant = variants[randomInt(variants.length)];
  const elementId = randomInt(100000, 300000).toString();

  return {
    id: `${variant.id}-${elementId}`,
    elementId,
    variantId: variant.id,
    variantName: variant.name,
    productId: variant.productId,
    productName: variant.productName,
    category: variant.category,
    inputs: variant.inputs,
  };
});
