import React, { useState } from "react";

import { useObserver } from "mobx-react";

import {
  DetailsSidebar,
  DetailsSidebarContent,
  DetailsSidebarHeader,
} from "../../Commons/DetailsSidebar";

import CustomPagination from "../../Commons/CustomPagination";
import instanceThumbnail1 from "../../../images/balcony_thumbnail.png";
import instanceThumbnail2 from "../../../images/wall_thumbnail.jpg";
import instanceThumbnail3 from "../../../images/wall_with_door_thumbnail.jpg";
import settingsStore from "../../../stores/SettingsStore";
import SidebarThumbnail from "../../Commons/SidebarThumbnail";
import CustomAccordion from "../../Commons/CustomAccordion";
import { LabelValueTableRow } from "../../Commons/LabelValueTable/types";
import LabelValueTable from "../../Commons/LabelValueTable";
import midStore from "../../../stores/MidStore";

function InstanceDetailsPanel(): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState(10);

  if (
    settingsStore.selectedInstances.length &&
    selectedIndex >= settingsStore.selectedInstances.length
  ) {
    setSelectedIndex(settingsStore.selectedInstances.length - 1);
  }

  const handleCloseButtonClick = () => {
    settingsStore.showInstanceDetailsPanel = false;
  };
  const handlePaginationChange = (index: number) => {
    setSelectedIndex(index);
  };

  const currentInstance =
    selectedIndex !== null &&
    selectedIndex < settingsStore.selectedInstances.length
      ? settingsStore.selectedInstances[selectedIndex]
      : null;

  let instanceThumbnail: string | null = null;

  switch (currentInstance?.productId) {
    case "0001": {
      instanceThumbnail = instanceThumbnail1;
      break;
    }
    case "0002": {
      instanceThumbnail = instanceThumbnail2;
      break;
    }
    default: {
      instanceThumbnail = instanceThumbnail3;
    }
  }

  const productInfo: LabelValueTableRow[] = [
    { label: "IAM File", value: "sdvasfasdf.iam" },
    { label: "Category", value: currentInstance?.category },
    { label: "Family Type", value: currentInstance?.variantName },
    { label: "Element ID", value: currentInstance?.elementId },
    { label: "Product", value: currentInstance?.productName },
    {
      label: "Variant Instances",
      value: midStore.instances
        ? midStore.instances.filter(
            (instance) => instance.variantId === currentInstance?.variantId
          ).length
        : 0,
    },
    { label: "Assigned", value: "3" },
  ];

  const inputsInfo: LabelValueTableRow[] = currentInstance
    ? currentInstance.inputs.map((input) => ({
        label: input.unit ? `${input.name} (${input.unit})` : `${input.name}`,
        value: (input.value && input.value.toString()) || "",
      }))
    : [];

  const outputInfo: LabelValueTableRow[] = [
    { label: "Revit Family (RFA)", value: <i>None uploaded</i> },
    { label: "Shop Drawings", value: <i>None uploaded</i> },
    { label: "Bill of Materials", value: <i>None uploaded</i> },
    { label: "Neutral format", value: <i>None uploaded</i> },
  ];

  return useObserver(() => (
    <DetailsSidebar sx={{ backgroundColor: "white" }}>
      <DetailsSidebarHeader onClose={handleCloseButtonClick}>
        <CustomPagination
          count={settingsStore.selectedInstances.length}
          currentIndex={selectedIndex}
          onChange={handlePaginationChange}
        />
      </DetailsSidebarHeader>
      <DetailsSidebarContent>
        <CustomAccordion title="Preview">
          <SidebarThumbnail src={instanceThumbnail || ""} />
        </CustomAccordion>
        <CustomAccordion title="Product Summary">
          <LabelValueTable data={productInfo} />
        </CustomAccordion>
        <CustomAccordion title={`Inputs (${inputsInfo.length})`}>
          <LabelValueTable data={inputsInfo} />
        </CustomAccordion>
        <CustomAccordion title="Outputs (4)">
          <LabelValueTable data={outputInfo} />
        </CustomAccordion>
      </DetailsSidebarContent>
    </DetailsSidebar>
  ));
}

export default InstanceDetailsPanel;
