import * as React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import projectImage0 from "../../../images/project0.jpeg";
import noProjectImage from "../../../images/3d-settings-icon-light-slate.png";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import {
  AccountLogo,
  AccountLogoTitleContainer,
  ProjectSelectorPanelPopoverContainer,
  Search,
  SearchIconWrapper,
  SelectedProjectHeaderContainer,
  SelectorsContainer,
  StyledInputBase,
} from "./style";
import { useObserver } from "mobx-react";
import AccountsList from "../AccountsList";
import ProjectsList from "../ProjectsList";
import accStore from "../../../stores/AccStore";
import { Tooltip } from "@mui/material";

export const ProjectSelectorPanel = (): JSX.Element => {
  const [anchorElProject, setAnchorElProject] =
    React.useState<null | HTMLElement>(null);

  const handleOpenProjectMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProject(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setAnchorElProject(null);
    accStore.projectId = "1234";
  };

  return useObserver(() => (
    <>
      <SelectedProjectHeaderContainer onClick={handleOpenProjectMenu}>
        <AccountLogoTitleContainer>
          <AccountLogo
            src={accStore.projectId ? projectImage0 : noProjectImage}
            alt=""
          />
          {accStore.projectId ? (
            <Tooltip title="ACME Extensive Building Project Design #123">
              <Typography variant="body2" noWrap component="div">
                ACME Extensive Building Project Design #123
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2" noWrap component="div">
              <strong>Select account and project</strong>
            </Typography>
          )}
        </AccountLogoTitleContainer>

        <ArrowDropDownIcon
          sx={{
            transform: anchorElProject ? "rotate(180deg)" : "",
          }}
        />
      </SelectedProjectHeaderContainer>
      <Popover
        id="projectPopover"
        open={Boolean(anchorElProject)}
        anchorEl={anchorElProject}
        onClose={handleCloseProjectMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ProjectSelectorPanelPopoverContainer>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search all project"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Divider />

          <SelectorsContainer>
            <AccountsList visible={!accStore.accountId} />
            <ProjectsList
              visible={!!accStore.accountId}
              onClick={handleCloseProjectMenu}
            />
          </SelectorsContainer>
        </ProjectSelectorPanelPopoverContainer>
      </Popover>
    </>
  ));
};
