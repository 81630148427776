import { makeAutoObservable } from "mobx";
import { MockFolderContentRow } from "../components/ProductsPage/ModelsFolderContent/mocks";

class AccStore {
  accountId?: string | null;
  accountName?: string | null;
  projectId?: string | null;
  projectName?: string | null;
  selectedModel?: MockFolderContentRow | null;
  currentModel?: MockFolderContentRow | null;

  constructor() {
    makeAutoObservable(this);
  }
}
const accStore = new AccStore();

export default accStore;
