import * as React from "react";

import Box from "@mui/material/Box";
import TreeItem from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";

import { HigFolder, HigFolderOpen } from "../../Commons/CustomIcon/HigIcons";
import { randomKey } from "../../Commons/functions";

export interface FolderTreeItemProps {
  id: string;
  label: string;
  subFolders?: FolderTreeItemProps[];
  expanded?: string[];
}

export const FolderItem = (props: FolderTreeItemProps): JSX.Element => {
  const { id, label, subFolders, expanded } = props;
  return (
    <TreeItem
      nodeId={id}
      label={
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginRight: "8px" }}>
            {expanded?.find((nodeId) => nodeId === id) ? (
              <HigFolderOpen />
            ) : (
              <HigFolder />
            )}
          </Box>
          <Typography variant="body2" noWrap>
            {label}
          </Typography>
        </Box>
      }
    >
      {subFolders
        ?.map((item) => ({ expanded, ...item }))
        .map((item) => (
          <FolderItem key={randomKey(item.id)} {...item} />
        ))}
    </TreeItem>
  );
};
