import * as React from "react";
import Box from "@mui/material/Box";
import { AppLogoImage, HeaderDarkTheme } from "../Header/HeaderAppBar/style";
import { Alert, FormControl, TextField, ThemeProvider } from "@mui/material";
import logoMid from "../../images/logo.png";
import Button from "@mui/material/Button";
import { useState } from "react";
import md5 from "md5";
import { validPasswordHash } from "./mock";
import settingsStore from "../../stores/SettingsStore";

export const PortalPassword = (): JSX.Element => {
  const [password, setPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);

  const handlePasswordChange = (event: any) => {
    setPassword(md5(event.target.value));
    setInvalidPassword(false);
  };

  const handlePasswordKeypress = (event: any) => {
    if (event.charCode === 13) {
      handleEnterClick();
    }
  };

  const handleEnterClick = () => {
    if (password === validPasswordHash) {
      settingsStore.portalPassword = password;
      window.localStorage.setItem("portalPassword", password);
    } else {
      setInvalidPassword(true);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#222",
        color: "#fff",
        paddingTop: "20vh",
      }}
    >
      <AppLogoImage src={logoMid} alt="" />
      <ThemeProvider theme={HeaderDarkTheme}>
        <Box
          sx={{
            marginTop: "48px",
            width: "400px",
            display: "flex",
            flexDirection: "row",
            gap: "12px",
          }}
        >
          <FormControl fullWidth>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              onChange={handlePasswordChange}
              onKeyPress={handlePasswordKeypress}
            />
          </FormControl>
          <Button
            variant="outlined"
            sx={{ paddingLeft: "24px", paddingRight: "24px" }}
            onClick={handleEnterClick}
          >
            Enter
          </Button>
        </Box>
        {invalidPassword && (
          <Alert severity="error" sx={{ width: "400px", marginTop: "24px" }}>
            Invalid password
          </Alert>
        )}
      </ThemeProvider>
    </Box>
  );
};
