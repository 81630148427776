import * as React from "react";

import { SidebarThumbnailContainer, SidebarThumbnailImage } from "./style";

export interface SidebarThumbnailProps {
  src: string;
  alt?: string;
}

export const SidebarThumbnail = ({
  src,
  alt,
}: SidebarThumbnailProps): JSX.Element => (
  <SidebarThumbnailContainer>
    <SidebarThumbnailImage src={src} alt={alt || ""} />
  </SidebarThumbnailContainer>
);
