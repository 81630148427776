import * as React from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { useObserver } from "mobx-react";

import accStore from "../../stores/AccStore";
import AdskIcon from "../../images/autodesk-collections-icon-dark-slate.png";
import CustomIcon from "../../components/Commons/CustomIcon";

import ModelDetails from "../../components/ProductsPage/ModelDetails";
import ModelsFolderBrowser from "../../components/ProductsPage/ModelsFolderBrowser";
import settingsStore from "../../stores/SettingsStore";

import { ModelsFolderContent } from "../../components/ProductsPage/ModelsFolderContent/ModelsFolderContent";
import InstancesPanel from "../../components/ProductsPage/InstancesPanel";
import { ProductsPageContainer, ProductsPageTabBar } from "./styles";

const ProductsPage = (): JSX.Element => {
  const handleChangeTab = (_: any, value: string) => {
    settingsStore.productsSelectedTab = value;
  };

  return useObserver(() => (
    <ProductsPageContainer>
      <ProductsPageTabBar>
        <Tabs
          orientation="vertical"
          value={settingsStore.productsSelectedTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          sx={{ backgroundColor: "#eee", minWidth: "100px", maxWidth: "100px" }}
        >
          <Tab
            value="models"
            label="Models"
            icon={<CustomIcon icon={AdskIcon} width="80px" />}
          />
          <Tab
            value="instances"
            label="Instances"
            disabled={!accStore.currentModel}
            icon={
              <CustomIcon
                icon={AdskIcon}
                width="80px"
                opacity={!accStore.currentModel ? 0.5 : 1}
              />
            }
          />
        </Tabs>
      </ProductsPageTabBar>
      {settingsStore.productsSelectedTab === "instances" && <InstancesPanel />}
      {settingsStore.productsSelectedTab === "models" && (
        <>
          <ModelsFolderBrowser />
          <ModelsFolderContent />
          {accStore.selectedModel && <ModelDetails />}
        </>
      )}
    </ProductsPageContainer>
  ));
};

export default ProductsPage;
