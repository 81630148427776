import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
} from "@mui/x-data-grid";

import { mockFolderContent, MockFolderContentRow } from "./mocks";
import { useEffect, useState } from "react";
import { useObserver } from "mobx-react";

import accStore from "../../../stores/AccStore";

import { HigFile, HigSearch } from "../../Commons/CustomIcon/HigIcons";
import {
  FilenameCell,
  ModelsFolderContentContainer,
  ModelsFolderContentDataGrid,
  ModelsFolderContentHeader,
} from "./style";

const renderFilenameCell = (params: GridRenderCellParams): React.ReactNode =>
  useObserver(() => (
    <FilenameCell>
      <HigFile />
      <Box sx={{ marginLeft: "6px", flexGrow: "10" }}>
        <Typography variant="body2" noWrap>
          {params.value}
        </Typography>
      </Box>
      {accStore.currentModel?.id === params.id && (
        <Chip
          color="primary"
          variant="outlined"
          label={<strong>Selected</strong>}
          size="small"
        />
      )}
    </FilenameCell>
  ));

const renderVersionCell = (params: GridRenderCellParams): React.ReactNode => (
  <Chip label={params.value} size="small" />
);

const columns: GridColDef[] = [
  {
    field: "fileName",
    headerName: "Name",
    width: 370,
    renderCell: renderFilenameCell,
  },
  {
    field: "version",
    headerName: "Version",
    width: 70,
    renderCell: renderVersionCell,
    align: "center",
  },
  {
    field: "modifiedOn",
    headerName: "Last updated",
    width: 150,
  },
  {
    field: "productsCount",
    headerName: "Products",
    width: 80,
    align: "right",
    type: "number",
  },
  {
    field: "variantsCount",
    headerName: "Variants",
    width: 80,
    align: "right",
    type: "number",
  },
  {
    field: "instancesCount",
    headerName: "Instances",
    width: 80,
    align: "right",
    type: "number",
  },
];

export const ModelsFolderContent = (): JSX.Element => {
  const [keywords, setKeywords] = useState("");
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  let gridData = mockFolderContent.filter((row) =>
    row.fileName.toUpperCase().includes(keywords.toUpperCase())
  );

  const handleRowClick = (rowParams: GridRowParams<MockFolderContentRow>) => {
    if (accStore.selectedModel?.id === rowParams.id) {
      accStore.selectedModel = null;
      setSelectionModel([]);
    } else {
      accStore.selectedModel = rowParams.row;
      setSelectionModel([rowParams.id]);
    }
  };

  const handleKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  useEffect(() => {
    gridData = mockFolderContent.filter((row) =>
      row.fileName.toUpperCase().includes(keywords.toUpperCase())
    );

    if (selectionModel.length === 0 && accStore.currentModel) {
      setSelectionModel([accStore.currentModel.id]);
    }
  }, []);

  return useObserver(() => (
    <ModelsFolderContentContainer>
      <ModelsFolderContentHeader>
        <ButtonGroup variant="outlined">
          <Button>
            <Typography variant="body2" noWrap>
              Open folder in Docs
            </Typography>
          </Button>
        </ButtonGroup>
        <TextField
          id="SearchTextField"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HigSearch />
              </InputAdornment>
            ),
          }}
          sx={{ padding: "0px", minWidth: "300px", marginRight: "12px" }}
          size="small"
          placeholder="search files by name"
          value={keywords}
          onChange={handleKeywordsChange}
        />
      </ModelsFolderContentHeader>
      <ModelsFolderContentDataGrid>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#eee",
            },
            fontSize: "14px",
          }}
          rows={gridData}
          columns={columns}
          rowsPerPageOptions={[5]}
          rowHeight={34}
          headerHeight={34}
          onRowClick={handleRowClick}
          selectionModel={selectionModel}
        />
      </ModelsFolderContentDataGrid>
    </ModelsFolderContentContainer>
  ));
};
