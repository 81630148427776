import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const ModelsFolderContentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: "10",
  alignItems: "stretch",
  paddingLeft: "12px",
  backgroundColor: "white",
}));

export const ModelsFolderContentHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  minHeight: "60px",
  maxHeight: "60px",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const ModelsFolderContentDataGrid = styled(Box)(() => ({
  flexGrow: "10",
  overflow: "scroll",
}));

export const FilenameCell = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));
