export enum InputType {
  NumberType,
  StringType,
  BooleanType,
}

export interface ProductInput {
  name: string;
  type: InputType;
  unit?: string;
}

export interface Product {
  id: string;
  name: string;
  inputs: ProductInput[];
}

export interface VariantInput {
  name: string;
  unit: string;
  value: string | number | boolean | null | undefined;
}

export interface Variant {
  id: string;
  name: string;
  productId: string;
  productName: string;
  category: string;
  inputs: VariantInput[];
}

export interface Instance {
  id: string;
  elementId: string;
  variantId: string;
  variantName: string;
  productId: string;
  productName: string;
  category: string;
  inputs: VariantInput[];
  assigned?: boolean;
}
