import * as React from "react";

import Button from "@mui/material/Button";

import { useObserver } from "mobx-react";
import { useState } from "react";

import accStore from "../../../stores/AccStore";
import ConfirmationDialog from "../../Commons/ConfirmationDialog";
import modelThumbnail1 from "../../../images/rvt_thumbnail.png";
import modelThumbnail2 from "../../../images/rvt_thumbnail2.png";
import settingsStore from "../../../stores/SettingsStore";
import SidebarThumbnail from "../../Commons/SidebarThumbnail";
import LabelValueTable from "../../Commons/LabelValueTable";
import { LabelValueTableRow } from "../../Commons/LabelValueTable/types";

import {
  DetailsSidebar,
  DetailsSidebarContent,
  DetailsSidebarHeader,
} from "../../Commons/DetailsSidebar";
import CustomAccordion from "../../Commons/CustomAccordion";

export const ModelDetails = (): JSX.Element => {
  const [openConfirmReplace, setOpenConfirmReplace] = useState(false);
  const handleConfirmReplaceOpen = () => setOpenConfirmReplace(true);
  const handleConfirmReplaceClose = () => setOpenConfirmReplace(false);

  const [openConfirmUnselect, setOpenConfirmUnselect] = useState(false);
  const handleConfirmUnselectOpen = () => setOpenConfirmUnselect(true);
  const handleConfirmUnselectClose = () => setOpenConfirmUnselect(false);

  const fileInfo: LabelValueTableRow[] = [
    { label: "Name", value: accStore.selectedModel?.fileName },
    { label: "Version", value: accStore.selectedModel?.version },
    { label: "Last Updated", value: accStore.selectedModel?.modifiedOn },
    { label: "Products", value: accStore.selectedModel?.productsCount },
    { label: "Variants", value: accStore.selectedModel?.variantsCount },
    { label: "Instances", value: accStore.selectedModel?.instancesCount },
    {
      label: "Assigned",
      value: `${accStore.selectedModel?.assignedCount} of ${accStore.selectedModel?.instancesCount}`,
    },
  ];

  const handleSelectModelClick = () => {
    accStore.currentModel = accStore.selectedModel;
    settingsStore.productsSelectedTab = "instances";
  };

  const handleCloseButtonClick = () => {
    accStore.selectedModel = null;
  };

  const handleUnselectModel = () => {
    accStore.currentModel = null;
  };

  return useObserver(() => (
    <>
      <DetailsSidebar sx={{ backgroundColor: "#eee" }}>
        <DetailsSidebarHeader onClose={handleCloseButtonClick}>
          {!accStore.currentModel && (
            <Button variant="contained" onClick={handleSelectModelClick}>
              Select Model
            </Button>
          )}
          {accStore.currentModel &&
            accStore.currentModel.id === accStore.selectedModel?.id && (
              <Button variant="outlined" onClick={handleConfirmUnselectOpen}>
                Unselect Model
              </Button>
            )}
          {accStore.currentModel &&
            accStore.currentModel.id !== accStore.selectedModel?.id && (
              <Button
                variant="contained"
                onClick={handleConfirmReplaceOpen}
                size="small"
              >
                Change Model
              </Button>
            )}
        </DetailsSidebarHeader>
        <DetailsSidebarContent>
          <CustomAccordion title="Preview">
            <SidebarThumbnail
              src={
                accStore.selectedModel &&
                accStore.selectedModel.instancesCount % 2
                  ? modelThumbnail1
                  : modelThumbnail2
              }
            />
          </CustomAccordion>
          <CustomAccordion title="Model Details">
            <LabelValueTable data={fileInfo} />
          </CustomAccordion>
        </DetailsSidebarContent>
      </DetailsSidebar>
      <ConfirmationDialog
        open={openConfirmReplace}
        message="Confirm replacing the current selected Model?"
        onCancel={handleConfirmReplaceClose}
        onConfirm={() => {
          handleSelectModelClick();
          handleConfirmReplaceClose();
        }}
      />
      <ConfirmationDialog
        open={openConfirmUnselect}
        message="Confirm unselecting the current Model?"
        onCancel={handleConfirmUnselectClose}
        onConfirm={() => {
          handleUnselectModel();
          handleConfirmUnselectClose();
        }}
      />
    </>
  ));
};
