import * as React from "react";
import { useState } from "react";
import {
  CustomAccordionContainer,
  CustomAccordionContent,
  CustomAccordionHeader,
  CustomAccordionTitle,
} from "./style";
import IconButton from "@mui/material/IconButton";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

export interface CustomAccordionProps {
  title: any;
  children?: any;
}
export const CustomAccordion = ({
  title,
  children,
}: CustomAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <CustomAccordionContainer>
      <CustomAccordionHeader>
        <IconButton onClick={handleExpandToggle} size="small">
          {expanded ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
        <CustomAccordionTitle variant="body2">
          <strong>{title}</strong>
        </CustomAccordionTitle>
      </CustomAccordionHeader>
      <CustomAccordionContent sx={{ maxHeight: !expanded ? "0px" : "100%" }}>
        {children}
      </CustomAccordionContent>
    </CustomAccordionContainer>
  );
};
